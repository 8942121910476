<template>
    <FormWrapper :is-loading="isLoading">
        <PageTitle title="Pages" slot="title" link="/helpContent/Page List" />
        <DataTable :actions="true"
            :data="pageList"
            :columns="tableConfig"
            :is-loading="isLoading"
            @on-page-change="pageChange"
            @on-selection-change="selectChange"
            :pagination="pagination"
            @on-sort-change="sortChange">
            <template slot="actionsLeft">
                <TableButton @click="() => add()">
                    <img :src="require(`Assets/images/plus-1.png`)"/>
                  Add
                </TableButton>
                <TableButton @click="() => edit()">
                    <img :src="require(`Assets/images/edit-1.png`)"/>
                  Edit
                </TableButton>
                <TableButton @click="() => remove()">
                    <img :src="require(`Assets/images/del.png`)"/>
                    Delete
                </TableButton>
            </template>
        </DataTable>
        <Popup title="Information" :value="warningModal.visible" @close="() => showWarningModal(false)">
            {{ warningModal.message }}
            <div slot="footer">
                <FormButton v-if="warningModal.buttons.cancel" @click="() => showWarningModal(false)">Cancel</FormButton>
                <FormButton v-if="warningModal.buttons.confirm" type="success" @click="confirmDelete">Confirm</FormButton>
                <FormButton v-if="warningModal.buttons.ok" @click="() => showWarningModal(false)">Ok</FormButton>
            </div>
        </Popup>
        <Popup title="Success" type="success" :value="showMessage.isVisible" @close="() => (showMessage.isVisible = false)" :closable="true">
            {{ showMessage.message }}
        </Popup>
    </FormWrapper>
</template> 

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import DataTable from 'Components/DataTable';
    import loadingMixin from 'Mixins/loadingMixin';
    import {getPageList, deletePage} from '../api';
    import tableConfig from './table';
    import validateSelectedRows from "Mixins/validateSelectedRows";
    export default {
        name: "List",
        mixins: [loadingMixin, validateSelectedRows],
        components: {
            FormWrapper,
            DataTable,
            PageTitle
        },
        data() {
            return {
                sorting: {
                    key: 'PageName',
                    order: 'asc'
                },
                pagination: {
                    perPage: 10,
                    page: 1,
                    total: 0
                },
                tableConfig,
                pageList: [],
                selectedRows: [],
                showMessage: {
                    isVisible: false,
                    message: "",
                }
            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getData()
        },
        methods: {
            add(){
                this.$router.push({ path: `/addUpdatePage` });
            },
            remove(){
                if (this.validateSelectedLength(1)) {
                    if (this.deleteSelectedValue()) {
                    }
                }
            },
            edit(){
                if (this.validateSelectedLength(1)) {
                    this.$router.push({ path: `/addUpdatePage/${btoa(this.selectedRows[0].Id)}` });
                }
            },
            selectChange(data) {
                this.selectedRows = data;
            },
            sortChange(data) {
                this.sorting.key = data.key;
                this.sorting.order = data.order;
                this.getData()
            },
            confirmDelete() {
                this.showWarningModal(false);
                const data = {
                    pageId: this.selectedRows[0].Id,
                };
                
                deletePage(data).then((res) => {
                    this.selectedRows=[];
                    this.warningModal.buttons.ok =true;
                    this.warningModal.buttons.cancel =false;
                    this.warningModal.buttons.confirm =false;
                    this.showMessage.isVisible = true;
                    this.showMessage.message = res.data[0].message;
                    this.getData();
                });
            },
            getData() {
                setTimeout(() => {
                    const { key, order } = this.sorting;
                    const { perPage, page } = this.pagination;
                    const data = {
                        filterjson: {
                            sort: [{ key, order }],
                            paging: [
                                {
                                    startIndex: page,
                                    pageSize: perPage
                                }
                            ]
                        }
                    };
                    getPageList(data)
                        .then(this.handleResponse)
                        .catch(this.handleError)
                },
                50
                )
            },
            pageChange(page) {
                this.pagination.page = page;
                this.getData()
            },
            handleResponse(response) {
                this.hideLoader();
                this.pageList = response.data;
                this.pagination.total = response.NumberOfRecords;
            },
        }
    }
</script>

<style lang="scss" module>
    .pagination {
        margin: 10px 0 0;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        line-height: 26px;
    }

    .paginationText {
        margin: 0 20px;
    }

    .paginationButton {
        cursor: pointer;
        width: 26px;
        text-align: center;
        transition: background-color .2s ease-in-out,color .2s ease-in-out, box-shadow 200ms ease
    }

    .paginationButton:hover {
        background: #57a3f3;
        color: #fff;
        &:hover {
            box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
        }
    }
</style>
