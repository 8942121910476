export default [
    {
        type: "selection",
        width: 38,
        align: "center",
    },

    {
        title: 'Page Name',
        key: 'PageName',
        minWidth: 80,
    },

    {
        title: 'Is Grid Page',
        key: 'IsGridPage',
        minWidth: 80,
    },

    {
        title: 'Is Detail Page',
        key: 'IsDetailPage',
        minWidth: 80,
    }
]
